<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Select Type</label>
                      <v-select
                        v-model="type"
                        placeholder="None"
                        :options="typeOptions"
                        @input="handleType($event)"
                        label="name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>User Name</label>
                      <v-select
                        v-model="user"
                        placeholder="None"
                        label="name"
                        :options="userOptions"
                      >
                      <template #option="{ name, profile_image, username, surname }">
                      <b-avatar :src="getprofileImage(profile_image)" v-if="name !== 'All'" />
                      <span class="font-weight-bolder"> {{ name }} {{surname}}</span>
                      <span v-if="name !== 'All'">({{ username }})</span>
                    </template>
                    </v-select>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="4">
                    <b-form-group>
                      <label>Select Date</label>
                      <flat-pickr
                        class="form-control"
                        value=""
                        placeholder="Select Date"
                        :config="{
                          dateFormat: 'd/m/Y',
                          mode: 'range',
                        }"
                        style="background-color: transparent"
                        @input="getdata($event)"
                      />
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <div class="d-flex justify-content-end">
                  <b-button variant="primary" class="ml-2" @click="searchData">
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Search</span>
                  </b-button>
                </div>
              </b-card-code>
              <report-table :data="data" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
    BAvatar

  },
  data() {
    return {
      data: "",
      type:{type:'All',name:'All'},
      userOptions:[],
      typeOptions:[
        { type:'All',name:'All'},
      { type:'channelpartner',name:'Channel Partner'},
      { type:'employee',name:'Employee'},
      { type:'franchise',name:'Franchise'},
      ],
      user:'',
      clientname: "",
      vouchertype: "",
      startdate: "",
      enddate: "",
      result: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      fields: [
        {
          field: "id",
          label: "S.R. No",
          hidden: true,
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "user_id",
          label: "User",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        {
          field: "date",
          label: "Login Date",
          // filterOptions: { enabled: true, placeholder: "Search" },
        },
        // {
        //   field: "logout",
        //   label: "Logout Date",
        //   filterOptions: { enabled: true, placeholder: "Search" },
        // },
      ],
      clientOptions: [],
      imagePath: process.env.VUE_APP_IMAGE_PATH,

    };
  },
  mounted() {
    this.searchData();
    this.getUsers();
  },
  methods: {
     getprofileImage(image) {
      if (image !== undefined && image !== null && image !== '') {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image
        return test
      }
      return image
    },
    handleType(e){
      if(e && e.type == 'All'){
          this.getUsers()
      }
      else if(e && e.type == 'channelpartner'){
        this.getCp()
      }
      else if(e && e.type == 'employee'){
        this.getEmp()
      }
      else if(e && e.type == 'franchise'){
        this.getFr()
      }
    },
    async getUsers(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      }).then((response) => {
        this.userOptions = [{name:'All'}]
        this.userOptions = [...this.userOptions,...response.data.data];
      });
    },
    async getCp(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getChannelpartner`,
      }).then((response) => {
       this.userOptions = [{name:'All'}]
        this.userOptions = [...this.userOptions, ...response.data.data];
      });
    },
    async getEmp(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      }).then((response) => {
       this.userOptions = [{name:'All'}]
        this.userOptions = [...this.userOptions,...response.data.data];
      });
    },
    async getFr(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getFranchise`,
      }).then((response) => {
        this.userOptions = [{name:'All'}]
        this.userOptions = [...this.userOptions, ...response.data.data];
        this.$forceUpdate()
      });
    },
    // async getSupllier() {
    //   await axios({
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/ledger`,
    //   }).then((response) => {
    //     this.clientOptions = response.data.data.data;
    //   });
    // },
    // getdata(selectedDates) {
    //   this.result = selectedDates.split(" to ");
    //   this.startdate = this.result[0];
    //   this.enddate = this.result[1];
    // },
    async searchData(tableData) {
        let data = {
          type: this.type?.type,
          userid:this.user?.id
        };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/usersloginreport`,
        data:data
      }).then((response) => {
        this.data = response.data.data;
        this.data.map((item) => {
          item.user_id = item.user_id[0] ? item.user_id[0].name + ' ' + (item.user_id[0].surname ? item.user_id[0].surname : '') : "";
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
